import React from "react";

export const useTailwindScreenSizes = ({
  initialWidth = 1280,
}: {
  initialWidth?: number;
}) => {
  const [currentWidth, setCurrentWidth] = React.useState(initialWidth);

  React.useEffect(() => {
    if (typeof window === "undefined") return;

    const handleScreenResize = () => {
      const width = window.innerWidth;
      setCurrentWidth(width);
    };
    handleScreenResize();

    window.addEventListener("resize", handleScreenResize);
    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, []);

  const getResponsive = () => {
    if (currentWidth > 1536) {
      return "2xl";
    }

    if (currentWidth > 1280) {
      return "xl";
    }

    if (currentWidth > 1024) {
      return "lg";
    }

    if (currentWidth > 768) {
      return "md";
    }
    if (currentWidth > 640) {
      return "sm";
    }

    return "very small";
  };
  const screenSize = getResponsive();
  const isLargeScreen =
    screenSize === "lg" || screenSize === "xl" || screenSize === "2xl";
  return { currentWidth, getResponsive, isLargeScreen };
};
